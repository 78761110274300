/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Colors */
:root {
  --primary-color: #0A3EA6;
  --dark-blue: #073673;
  --gold: #BF7E04;
  --orange: #A64F03;
  --dark-brown: #591902;
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black !important;
  color: white !important;
  margin-top: 60px;
  overflow-x: hidden;
}

/* Container Styles */
.homeContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Title Section */
.titleContainer {
  background-image: url('/public/images/BackgrundImages/snow3.png');
  background-size: 100% auto;
  background-position: top center; 
   margin-top: 40px;  
  padding: 25px 0 165px;
  margin-bottom: -30px;
  text-align: center;
  color: white;
  transform: scale(1.3); 
}



.titleImage {
  width: 80%;
  max-width: 200px;
  margin: 80px auto 20px;
}

.homeTitle {
  color: white;
}

.titleContainer::before {
  content: ''; 
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1); 
}

/* Game Section */
.gameSectionContainer {
  padding: 20px 0;
  text-align: center;
}

.ImageGames {
  display: flex;
  justify-content: center;
  margin-bottom: 50px; 
}

.gameSection img {
  margin-top: 40px;
  height: 350px; 
  object-fit: cover;
}

.gamesTitle {
  color: var(--gold);
}


/* About Us Section */
.aboutUsSection {
  background-image: url('/public/images/BackgrundImages/creepy_forest2.png');
  background-size: cover;
  background-position: center;
  padding: 150px 0;
  text-align: center;
  color: white;
  transform: scale(1.3); 
  margin-top: -30px;
  margin-bottom: 50px; 
}

.aboutUsContent {
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 110px;
}

.learnMoreButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--gold);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  /* pointer-events: auto; */
}

.learnMoreButton:hover {

  background-color: var(--orange);

}

/* News Section */
.newsSection {
  padding: 50px 0;
  text-align: center;
  /* pointer-events: auto; */

}

.newsImage img {
  max-width: 500px;
  margin-top: -80px;
}

.newsContent {
  text-align: center;
  background-color: rgba(23, 23, 23, 0.7);
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(-2deg) skewX(-20deg); 
  pointer-events: auto;
}


.newsContent h2 {
  color: #BF7E04;
  font-size: 24px;
  margin-bottom: 5px;
}

.newsContent p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}

.newsContent a {
  color: #BF7E04;
  text-decoration: none;
  font-weight: bold;
}

.newsContent a:hover {
  color: #A64F03;
}

.linkText {
  color: var(--gold);
  text-decoration: none;
}

.linkText:hover {
  color: var(--orange);
}

.withMargin {
  margin-top: 70px 
}



/* Contact Section */
.connectSection {
  padding: 50px 0; 
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center; 
  margin-top: 10px;
}

.connectImage {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.contactButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--gold);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 20px; 
}

.contactButton:hover {
  background-color: var(--orange);
}



