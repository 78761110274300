@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --primary-color: #0A3EA6;
  --gold: #BF7E04;
  --orange: #A64F03;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: rgb(15, 14, 14) !important;
  color: white !important;
  margin-top: 60px;
  overflow-x: hidden;
}

.titleContainer {
  background-image: url('/public/images/BackgrundImages/snow3.png');
  background-size: cover; 
  background-position: top center;
  margin-top: 20px;
  padding: 25px 0 290px;
  margin-bottom: 100px;
  width: 100%;
  text-align: center;
  color: white;
  transform: scale(1.1);
  position: relative;
}

.titleImage {
  /* width: 100%; */
  max-width: 400px;
  margin: 80px auto 20px;
  margin-top: 80px;
}

.titleContainer :nth-child(3){
  display: inline-block;
  padding: 10px 30px;
  background-color: var(--gold);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  margin: 20px;
}

.titleContainer :nth-child(3):hover {
  background-color: var(--orange);
}

.imageSection {
  background-image: url('/public/images/BackgrundImages/creepy_forest2.png');
  background-size: 112% 100%; 
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -30px;
  padding: 320px 0;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.imageListContainer {
  flex: 1; 
  display: flex;
  overflow-x: auto;

}

.imageList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;

}

.imageList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-start; 
  flex-wrap: nowrap; 
  overflow-x: auto; 
}

.imageListItem {
  flex: 0 0 auto;
  width: 200px;
  margin: 20px;
  text-align: center;
}

.imageListItem img {
  width: 100%;
  max-width: 100%; 
  height: auto; 
  object-fit: cover; 
}


button {
  margin: 0 5px;
  padding: 14px 20px;
  color: white;
  background-color: var(--gold);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: var(--orange);
}

.blackBackground {
  text-align: center;
  margin: auto 200px;
  margin-top: -110px;
}

.blackBackground :nth-child(1) {
  color: #BF7E04;
}

.features {
  background-image: url('/public/images/arsim/BackgrundGray.png');
  background-size: cover; 
  background-position: center;
  max-width:  70%;
  padding: 20px;
  margin-top: -50px;
  text-align: center;
  color: white;
  margin-left: 15%;
  margin-bottom: 150px;
}

/* .smallImageSection {
  background-image: url('/public/images/BackgrundImages/creepy_forest2.png');
  background-size: cover; 
  background-position: center bottom;
  padding: 120px;
  max-width:  100%;
  margin-left: 6%;
  margin-top: 20px;
  text-align: center;
  color: white;
  width: 90%;
} */

.smallImageList {
  justify-content: center;
}

.smallImageContainer {
  width: 180px;
  height: 180px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5px;
}

.smallImageContainer img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.image {
  background-color: black;
  padding: 20%;
}

.pagesSlider{
  text-align: center;
}

.smallImageContainer p {
  margin: 0;
  color: white; 
}

.row {
  display: flex;
  flex-direction: row;
}

.learnMoreSection{
  margin-top: -100px;
  text-align: center;
}
.learnText {
  margin: 30px;

}

.learnText h5 {
  color: #0A3EA6;
}

.learnText :nth-child(2) {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--gold);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  margin: 20px;
}

.learnText :nth-child(2):hover {
  background-color: var(--orange);
}



