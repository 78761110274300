.footer {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 40px 0;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.contactInfo,
.socialLinks,
.languageSwitcher {
  flex: 1;
  padding: 0 20px;
}

.footerHeading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.footerLink {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footerLink:hover {
  color: #ff9900;
}

.footerDisclaimer {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

/* Social media icons */
.socialMediaContainer {
  display: flex;
  justify-content: center;
  gap: 15px; 
}

.socialMediaIcon {
  width: 55px; 
  height: 55px; 
}


.socialMediaContainer .socialMediaTrapezoid .socialMediaIcon {
  background-color: #fff; 
  padding: 5px; 
  border-radius: 5px;
}

/* Language switcher */
.languageSwitcher p {
  font-size: 14px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    text-align: center;
  }

  .contactInfo,
  .socialLinks,
  .languageSwitcher {
    margin-bottom: 20px;
  }
}
