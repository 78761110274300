:root {
  --primary-color: #0A3EA6;
  --dark-blue: #073673;
  --gold: #BF7E04;
  --orange: #A64F03;
  --dark-brown: #591902;
}

.contactContainer {
  max-width: 80rem;
  margin: 0 auto;
  padding: 190px;
  text-align: center;
  background-image: url('/public/images/BackgrundImages/lave1.png');
  background-size: cover;
  background-position: center;
  color: white;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--gold);
  margin-top: -80px;
}

.text {
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputGroup {
  margin-bottom: 20px;
  width: 100%;
}

.labelGroup {
  display: flex;
  justify-content: space-between;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--gold);
}

.inputText {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--gold);
  border-radius: 0;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  margin-top: 5px;
  background-color: black;
}

.textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--gold);
  border-radius: 0;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  resize: vertical;
  margin-top: 5px;
  background-color: black;

}

.button {
  padding: 10px 20px;
  background-color: var(--gold);
  color: black;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  text-transform: uppercase;
}

.button:hover {
  background-color: #FFD700;
}

.submittedMessage {
  font-size: 1.2rem;
  color: var(--gold);
  margin-top: 20px;
}

.card {

  border-color: rgba(255, 255, 255, 0.7); 
}


