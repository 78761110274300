/* styles.module.css */
.logo {
  width: 150px;
}

.arrowIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  filter: invert(100%);
}

.languageFlag {
  width: 30px;
  height: auto;
}

.langMenu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0.5px;
  right: 8px;
}

.customPopover {
  color: white;
  border-radius: none;
  padding: 80px;
}

.PopoverContainer {
  display: none;
}

.gameBackground {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.005), rgba(152, 133, 133, 0.119)), url(/public/images/BackgrundImages/horizontal\ cloud\ wallpaperImg.png);
  background-size: 150%;
  background-position: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8); */
  background-color: black !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: #ffc107 !important;
}

.link:visited {
  background-color: #591902 !important;
}


